


const PageNotFound = (props) => {
  const {isMobile} = props
  return (
      <div>
        <div className="stars">
          <div className="central-body">
            <img alt={'404'}
                 style={{
                   marginTop: isMobile && '200px',
                   width: isMobile ? '50%' : '20%',
                   height:'50%',
                 }}
                 className="image-404"
                 src="http://salehriaz.com/404Page/img/404.svg" width="300px"/>
            <a href="/"
               className={`btn-go-home`}
               style={{
                 marginTop:isMobile && '100px',
               }}>GO BACK HOME</a>
          </div>
          <div className="objects">
            <img alt={'rocket'} className="object_rocket"
                 src="http://salehriaz.com/404Page/img/rocket.svg"
                 style={{
                   width:'100px'
                 }}
            />
            <div className="earth-moon">
              <img alt={'earth'} className="object_earth"
                   src="http://salehriaz.com/404Page/img/earth.svg"
                   style={{
                     width:'240px'
                   }}
              />
              <img alt={'moon'} className="object_moon"
                   src="http://salehriaz.com/404Page/img/moon.svg"
                   style={{
                     width:'160px'
                   }}
              />
            </div>
            <div className="box_astronaut">
              <img alt={'astronaut'} className="object_astronaut"
                   src="http://salehriaz.com/404Page/img/astronaut.svg"
                   style={{
                     width:'40px'
                   }}
              />
            </div>
          </div>
          <div className="glowing_stars">
            <div className="star"/>
            <div className="star"/>
            <div className="star"/>
            <div className="star"/>
            <div className="star"/>
          </div>
        </div>
      </div>

  );
};
export default PageNotFound;