import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useEffect, useState} from 'react';
import {SCREEN_SIZE} from './assets/ScreenResolutions/Resolutions';
import Main from './Screens/Main';
import {Route, Routes} from 'react-router-dom';
import PageNotFound from './components/404';

const App = () => {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= SCREEN_SIZE.MOBILE) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isMobile]);

  return (
      <div  className={'App'}>
        <Routes>
          <Route path={'/'} element={<Main isMobile={isMobile}/>}></Route>
          <Route path={'*'} element={<PageNotFound isMobile={isMobile} />}></Route>
        </Routes>
      </div>

  );
};

export default App;
