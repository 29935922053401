import {Container, Row, Col, Tab, Nav} from 'react-bootstrap';
import {ProjectCard} from './ProjectCard';
import colorSharp2 from '../assets/img/color-sharp2.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import YoutubePlayback from './YoutubePlayback';
import {Github} from 'react-bootstrap-icons';
import {WebProjects} from '../assets/Data/WebProjectsData';

export const Projects = (props) => {
  const {isMobile} = props;

  const renderWebProjects = () => {

    return (
        <Row>
          {
            WebProjects.map((project, index) => {
              return (
                  <ProjectCard
                      key={index}
                       project={project}
                  />
              );
            })
          }
        </Row>
    );
  };

  const renderGameProjects = () => {
    return (<div
            className={'container d-flex align-items-center justify-content-center'}>
          <YoutubePlayback isMobile={isMobile}/>
        </div>
    );
  };

  const renderCPPProject = () => {
    return (
        <div>
          <p>
            A console based program that ask user to input
            asteroid data than according to that generalize in
            categories which are likely to hit earth or not.
            After that calcualates the best mean of avoidance
            using binary tree to calculate the cheapest
            technique to avoid a collision.
            &nbsp;
            <a href={'https://github.com/achii97/Asteroid-Impact-Avoidance-System'}
               className={'text-white-50 text-decoration-none justify-content-center d-flex align-items-center'}>
              <Github/>
              &nbsp;
              Github
            </a>
          </p>
          <div
              className={'justify-content-center d-flex align-items-center'}>
            <img
                src={require('../assets/img/Asteroids.gif')}
                alt={'asteroids'}
                style={{
                  height: isMobile ? 'auto' : '500px',
                  width: isMobile ? '100%' : '1000px',
                }
                }
            />
          </div>
        </div>

    );

  };

  return (
      <section className="project" id="project">
        <Container>
          <Row>
            <Col size={12}>
              <TrackVisibility>
                {({isVisible}) =>
                    <div className={isVisible ?
                        'animate__animated animate__fadeIn' :
                        ''}>
                      <h2>Projects</h2>
                      {/*<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>*/}
                      <Tab.Container id="projects-tabs"
                                     defaultActiveKey="first">
                        <Nav variant="pills"
                             className="nav-pills mb-5 justify-content-center align-items-center cursor-pointer"
                             id="pills-tab">
                          <Nav.Item>
                            <Nav.Link eventKey="first"
                                      style={{fontSize: isMobile && '15.5px'}}>Web
                              Projects</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second"
                                      style={{fontSize: isMobile && '15.5px'}}>Game
                              Projects</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third" style={{
                              fontSize: isMobile && '10.5px',
                            }}><span
                                className={'position-absolute'}
                                style={{
                                  marginLeft: '-20px',
                                  marginTop: '-20px',
                                  color: 'red', fontSize: '40px',
                                }}>*</span>X - Nav System</Nav.Link>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content id="slideInUp" className={isVisible ?
                            'animate__animated animate__slideInUp' :
                            ''}>
                          <Tab.Pane eventKey="first">
                            {renderWebProjects()}
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            {renderGameProjects()}
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            {renderCPPProject()}
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>}
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
        <img alt={'bg-img'} className="background-image-right"
             src={colorSharp2}></img>
      </section>
  );
};
