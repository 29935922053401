import {Col} from 'react-bootstrap';

export const ProjectCard = (props) => {

  const {project} = props;

  const handleClick = (link) => {
    window.open(link, '_blank');
  };

  return (
      <Col size={12} sm={6} md={4} onClick={() => handleClick(project?.liveLink)}
           className={'cursor-pointer'}>
        <div className="proj-imgbx">
          <img alt={'img1'} src={project?.imgUrl} style={{
            height: '305px',
            width: '416px',
            objectFit: 'cover',
          }}/>
          <div className="proj-txtx">
            <h4>{project?.title}</h4>
            <span>{project?.description}</span>
          </div>
        </div>
      </Col>
  );
};
