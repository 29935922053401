import projImg3 from '../img/project-img3.png';
import FanzineHome from '../img/fanzineH.png';
import MazebaanHome from '../img/MazebaanH.png'
import GunnersHome from '../img/gunnersH.png';
import MyQhseHome from '../img/myQhse.png';
import FiHome from '../img/FiH.png';


export const WebProjects = [
  {
    title: 'Fanzine Sports',
    description: 'Sports App for news, social posts and videos across your favourite sports: Football, Cricket, NBA, NFL and MLB.',
    imgUrl: FanzineHome,
    repoLink: '',
    liveLink:'https://www.fanzine.com/',
  },
  {
    title: 'Mazebaan – Hunting comfort living',
    description: 'An online marketplace focused on short-term homestays and experiences.',
    imgUrl: MazebaanHome,
    repoLink: '',
    liveLink:'https://mazebaan.com/',
  },
  {
    title: 'Gunners.com',
    description: 'Latest Arsenal News, Transfers and Scores.',
    imgUrl: GunnersHome,
    repoLink: '',
    liveLink:'https://gunners.com/',
  },
  {
    title: 'Techzology',
    description: 'Integrated Management System for large enterprises - Custom Form Builder, Form Management, LMS & many more.',
    imgUrl: MyQhseHome,
    repoLink: '',
    liveLink:'http://myqhse.techzology.com/',
  },
  {
    title: 'Freeze Interactive | Software Development Company',
    description: 'Portfolio App for Software Development Company which focuses on building web and mobile applications',
    imgUrl: FiHome,
    repoLink: '',
    liveLink:'https://www.freezeinteractive.com/',
  },
  {
    title: 'Business Startup',
    description: 'Design & Development',
    imgUrl: projImg3,
    repoLink: '',
    liveLink:'',
  },
];
