import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
// import logo from '../assets/img/logo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/githubIcon.png';
import { HashLink } from 'react-router-hash-link';
import {AllContactLinks} from '../assets/Links/AllContactLinks';

export const NavBar = (props) => {
  // eslint-disable-next-line
  const {isMobile} =props
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  const renderNavBar = () =>
  {

    return(
        <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
          <Container>
            {/*<Navbar.Brand href="/">*/}
            {/*  <img src={logo} alt="Logo" />*/}
            {/*</Navbar.Brand>*/}
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <span className="navbar-toggler-icon"></span>
            </Navbar.Toggle>
            <Navbar.Collapse className={'justify-content-center'} id="basic-navbar-nav">
              <Nav
                  // className="ms-auto"
              >
                <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
                <Nav.Link href="#project" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
              </Nav>
              <span className="navbar-text">
              <div className="social-icon">
                <a href={AllContactLinks.linkedIn}><img src={navIcon1} alt="linkedInIcon" /></a>
                <a href={AllContactLinks.githubOld}><img src={navIcon2} alt="githubIcon" /></a>
              </div>
              <HashLink to='#connect'>
                <button className="vvd"><span>Let’s Connect</span></button>
              </HashLink>
            </span>
            </Navbar.Collapse>
          </Container>
        </Navbar>
    )

  }


  return (
      <div>
      {renderNavBar()}
      </div>

  )
}
