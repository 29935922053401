import {NavBar} from '../components/NavBar';
import {Banner} from '../components/Banner';
import {Skills} from '../components/Skills';
import {Projects} from '../components/Projects';
import {Contact} from '../components/Contact';
import {Footer} from '../components/Footer';

const Main = (props) => {
  const {isMobile} = props;

  const renderMain = () => {
    return (
        <div>
          <NavBar isMobile={isMobile}/>
          <Banner isMobile={isMobile}/>
          <Skills isMobile={isMobile}/>
          <Projects isMobile={isMobile}/>
          <Contact isMobile={isMobile}/>
          <Footer isMobile={isMobile}/>
        </div>
    );
  };
  return renderMain();

};

export default Main;