import { Container, Row, Col } from "react-bootstrap";
// import logo from "../assets/img/logo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/githubIcon.png";
import {AllContactLinks} from '../assets/Links/AllContactLinks';

export const Footer = (props) => {
  // eslint-disable-next-line
  const {isMobile} =props

  return (
    <footer className="footer">
      <Container className={'pt-5'}>
        <Row className="align-items-center">
          {/*<MailchimpForm />*/}
          <Col size={12} sm={6}>
            <p>
              Asfand Yar Khan - Full Stack Engineer, Game Developer & Space Enthusiast
            </p>
            {/*<img src={logo} alt="Logo" />*/}
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href={AllContactLinks.linkedIn}><img src={navIcon1} alt="Icon" /></a>
              <a href={AllContactLinks.githubOld}><img src={navIcon2} alt="Icon" /></a>
            </div>
            <p>Copyright 2022. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
