import meter1 from '../assets/img/meter1.svg';
import meter2 from '../assets/img/meter2.svg';
import meter3 from '../assets/img/meter3.svg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import arrow1 from "../assets/img/arrow1.svg";
// import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from '../assets/img/color-sharp.png';

export const Skills = (props) => {
  const {isMobile} =props
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: {max: 4000, min: 3000},
      items: 5,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 3,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 2,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };

  return (
      <section className="skill" id="skills">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="skill-bx wow zoomIn">
                <h2>Skills</h2>
                <p>
                  <div className={'d-flex justify-content-center text-start align-items-center'}>
                    <ol>
                      <li>
                        Website Development using (React, Gatsby, and Next JS).
                      </li>
                      <li> Scalable Backend using (Node, Express, LoopBack + MySQL, MongoDB and Redis).
                      </li>
                      <li>Container Orchestration (Rancher and Kubernetes).</li>
                      <li>Progressive Web Apps (PWA) using Ionic.</li>
                    </ol>
                  </div>
                </p>
                <Carousel
                    autoPlay
                    swipeable
                    responsive={responsive} infinite={true}
                          className="owl-carousel owl-theme skill-slider">
                  <div className={`item  ${isMobile && 'p-2'}`}>
                    <img src={meter1}  alt="Image1"/>
                    <h5>Frontend UI/UX</h5>
                  </div>
                  <div className={`item  ${isMobile && 'p-2'}`}>
                    <img src={meter3} alt="Image3"/>
                    <h5>Scalable Backends</h5>
                  </div>
                  <div className={`item  ${isMobile && 'p-2'}`}>
                    <img src={meter1} alt="Image4"/>
                    <h5>Progressive Web Apps</h5>
                  </div>
                  <div className={`item  ${isMobile && 'p-2'}`}>
                    <img src={meter2} alt="Image2"/>
                    <h5>Container Orchestration</h5>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image12"/>
      </section>
  );
};
