import React from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import {AllContactLinks} from '../assets/Links/AllContactLinks';

const YoutubePlayback = (props) =>
{
  const {isMobile} = props
  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const opts: YouTubeProps['opts'] = {
    height: isMobile ? 'auto' : '500',
    width: isMobile ? '100%' : '1080',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return <YouTube videoId={AllContactLinks.gameLOSMYTCode} opts={opts} onReady={onPlayerReady} />;
}

export  default YoutubePlayback